var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"filterContainerRef",staticClass:"relative flex items-center justify-between px-2 -mt-1 bg-white rounded-t",staticStyle:{"box-shadow":"0 5px 5px 0 rgba(0, 0, 0, 0.07)","z-index":"50","height":"55px"}},[_c('div',{staticClass:"flex gap-2 item-center"},[_c('button',{staticClass:"filter-base-dropdown-button app-form-reset",class:{
        'bg-gray-100 text-gray-700 border-gray-200': !_vm.isShown,
        'bg-white text-oDark border-gray-200': _vm.isShown,
      },attrs:{"aria-label":"Filter menu","aria-haspopup":"true"},on:{"click":_vm.onClickFilterMenu}},[_c('i',{staticClass:"fas fa-filter text-oCharcoal"}),_c('span',{staticClass:"font-semibold"},[_vm._v("Filters")]),_c('span',{class:"filter-pills-count",style:(("visibility: " + (_vm.computeFilterablesAppliedCount > 0 ? 'visible' : 'hidden')))},[_vm._v(" "+_vm._s(_vm.computeFilterablesAppliedCount)+" ")])]),_vm._l((_vm.computeVisiblePills),function(filter){return _c('TDropdown',{key:filter.key,attrs:{"variant":"filterPill"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
            var mousedownHandler = ref.mousedownHandler;
            var focusHandler = ref.focusHandler;
            var blurHandler = ref.blurHandler;
            var keydownHandler = ref.keydownHandler;
            var isShown = ref.isShown;
return [_c('button',{staticClass:"filter-base-dropdown-button app-form-reset",class:{
            'bg-gray-100 text-gray-700 border-gray-200': !isShown,
            'bg-white text-oDark border-gray-200 shadow-md': isShown,
            'filter-is-applied': filter.isApplied,
          },attrs:{"aria-label":"Filter menu","aria-haspopup":"true"},on:{"mousedown":mousedownHandler,"focus":focusHandler,"blur":blurHandler,"keydown":keydownHandler}},[_c('span',{staticClass:"text-sm font-bold"},[_vm._v(_vm._s(_vm.getFiltersTitle(filter)))]),(_vm.getFiltersTitleCount(filter) > 0)?_c('span',{class:"selected-filter-pills-count"},[_vm._v(" "+_vm._s(_vm.getFiltersTitleCount(filter))+" ")]):_vm._e(),_c('i',{staticClass:"fas fa-chevron-down"})])]}},{key:"default",fn:function(ref){
          var hide = ref.hide;
          var blurHandler = ref.blurHandler;
return [_c('div',{staticClass:"px-6 py-4 border-b"},[_c('TCheckbox',{class:"ml-1",attrs:{"model":_vm.filters[filter.key].all,"label":"All","name":"Vehicle Status","variant":"liveMapTitle","wrapped":""},on:{"click":function($event){return _vm.onFilterGroupSelectAllToggle({ group: filter.key })}}}),_c('div',{staticClass:"h-1"}),_c('div',{staticClass:"overflow-auto max-h-60 SB-FarhanShares"},_vm._l((_vm.filters[filter.key].models),function(modelValue,modelKey){return _c('div',{key:filter.key + modelKey,class:"filter-item"},[_c('TCheckbox',{attrs:{"model":modelValue,"label":filter.labels[modelKey],"name":filter.labels[modelKey],"variant":"liveMap","wrapped":""},on:{"change":function($event){return _vm.$store.commit('liveMap/onFilterChange', {
                    group: filter.key,
                    key: modelKey,
                    value: $event,
                  })}}}),_c('div',{staticClass:"filter-item-count"},[_vm._v(" "+_vm._s(_vm.getFilterCounts[filter.key] && _vm.getFilterCounts[filter.key][modelKey] && _vm.getFilterCounts[filter.key][modelKey].count ? _vm.getFilterCounts[filter.key][modelKey].count : '0')+" ")])],1)}),0)],1),_c('div',{staticClass:"flex justify-end gap-4 p-2 mt-4"},[_c('AppButton',{attrs:{"text":"Clear","variant":"secondary","height":"32px"},on:{"click":function($event){hide()
              _vm.onFilterGroupClear({ group: filter.key })},"blur":blurHandler}}),_c('AppButton',{attrs:{"text":("Show " + (_vm.getFilterGroupCounts[filter.key]
                  ? _vm.getFilterGroupCounts[filter.key].count
                  : 0) + " vehicles"),"variant":"green","height":"32px","disabled":_vm.shouldBeDisabled({ group: filter.key })},on:{"click":function($event){hide()
              _vm.onFilterGroupApply({ group: filter.key })},"blur":blurHandler}})],1)]}}],null,true)})}),(_vm.computeConcealedPillsCount > 0)?_c('TDropdown',{staticClass:"hidden sm:block",attrs:{"variant":"filterPill"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
              var mousedownHandler = ref.mousedownHandler;
              var focusHandler = ref.focusHandler;
              var blurHandler = ref.blurHandler;
              var keydownHandler = ref.keydownHandler;
              var isShown = ref.isShown;
return [_c('button',{staticClass:"flex items-center justify-center px-1 py-1 text-sm text-black border border-gray-200 rounded-full cursor-pointer app-form-reset hover:bg-white",class:{
            'bg-gray-100 text-gray-700 border-gray-200': !isShown,
            'bg-white text-oDark border-gray-200 shadow-md': isShown,
          },staticStyle:{"height":"35px","width":"35px"},attrs:{"aria-label":"Filter pill menu","aria-haspopup":"true"},on:{"mousedown":mousedownHandler,"focus":focusHandler,"blur":blurHandler,"keydown":keydownHandler}},[_vm._v(" "+_vm._s(_vm.computeConcealedPillsCount)+"+ ")])]}}],null,false,2314082622)},[_c('div',{staticClass:"flex flex-col gap-2 px-2 py-2"},_vm._l((_vm.computeConcealedPills),function(filter){return _c('TDropdown',{key:filter.key,attrs:{"variant":"filterPill"},scopedSlots:_vm._u([{key:"trigger",fn:function(ref){
            var mousedownHandler = ref.mousedownHandler;
            var focusHandler = ref.focusHandler;
            var blurHandler = ref.blurHandler;
            var keydownHandler = ref.keydownHandler;
            var isShown = ref.isShown;
return [_c('button',{staticClass:"filter-base-dropdown-button app-form-reset",class:{
                'bg-gray-100 text-gray-700 border-gray-200': !isShown,
                'bg-white text-oDark border-gray-200 shadow-md': isShown,
                'filter-is-applied': filter.isApplied,
              },attrs:{"aria-label":"Filter menu","aria-haspopup":"true"},on:{"mousedown":mousedownHandler,"focus":focusHandler,"blur":blurHandler,"keydown":keydownHandler}},[_c('span',{staticClass:"text-sm font-bold"},[_vm._v(_vm._s(filter.title))]),_c('i',{staticClass:"fas fa-chevron-down"})])]}},{key:"default",fn:function(ref){
              var hide = ref.hide;
              var blurHandler = ref.blurHandler;
return [_c('div',{staticClass:"px-6 py-4 border-b"},[_c('TCheckbox',{class:"ml-1",attrs:{"model":_vm.filters[filter.key].all,"label":"All","name":"Vehicle Status","variant":"liveMapTitle","wrapped":""},on:{"click":function($event){return _vm.onFilterGroupSelectAllToggle({ group: filter.key })}}}),_c('div',{staticClass:"h-1"}),_c('div',{staticClass:"overflow-auto max-h-60 SB-FarhanShares"},_vm._l((_vm.filters[filter.key].models),function(modelValue,modelKey){return _c('div',{key:filter.key + modelKey,class:"filter-item"},[_c('TCheckbox',{attrs:{"model":modelValue,"label":filter.labels[modelKey],"name":filter.labels[modelKey],"variant":"liveMap","wrapped":""},on:{"change":function($event){return _vm.$store.commit('liveMap/onFilterChange', {
                        group: filter.key,
                        key: modelKey,
                        value: $event,
                      })}}}),_c('div',{staticClass:"filter-item-count"},[_vm._v(" "+_vm._s(_vm.getFilterCounts[filter.key][modelKey].count)+" ")])],1)}),0)],1),_c('div',{staticClass:"flex justify-end gap-4 p-2 mt-4"},[_c('AppButton',{attrs:{"text":"Clear","variant":"secondary","height":"32px"},on:{"click":function($event){hide()
                  _vm.onFilterGroupClear({ group: filter.key })},"blur":blurHandler}}),_c('AppButton',{attrs:{"text":("Show " + (_vm.getFilterGroupCounts[filter.key]
                      ? _vm.getFilterGroupCounts[filter.key].count
                      : 0) + " vehicles"),"variant":"green","height":"32px","disabled":_vm.shouldBeDisabled({ group: filter.key })},on:{"click":function($event){hide()
                  _vm.onFilterGroupApply({ group: filter.key })},"blur":blurHandler}})],1)]}}],null,true)})}),1)]):_vm._e()],2),(_vm.currentTripId)?_c('div',{staticClass:"flex items-center gap-2 px-4 py-1 border rounded-full shadow-sm bg-gray-50"},[_c('span',{staticClass:"text-14px"},[_vm._v("Realtime")]),_c('TToggle',{attrs:{"variant":"success"},on:{"change":_vm.onChangeRealtime},model:{value:(_vm.isRealtime),callback:function ($$v) {_vm.isRealtime=$$v},expression:"isRealtime"}}),_c('span',{staticClass:"w-5 font-bold text-14px"},[_vm._v(" "+_vm._s(_vm.isRealtime ? 'On' : 'Off')+" ")])],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }