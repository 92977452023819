<template>
  <div class="icon-wrapper">
    <img v-if="getIcon" :src="getIcon" :class="getIconClass" />
  </div>
</template>

<script>
export default {
  name: 'VehicleFlagIcon',
  props: {
    name: {
      type: String,
    },
    size: {
      required: false,
      type: String,
    },
  },
  computed: {
    collection() {
      return {
        // operational
        charging_pick: {
          icon: require('@/assets/img/flags/on-charging-pick.svg'),
        },
        maintainance: {
          icon: require('@/assets/img/flags/on-maintainance.svg'),
        },
        rebalance: {
          icon: require('@/assets/img/flags/on-rebalance.svg'),
        },
        swap_battery: {
          icon: require('@/assets/img/flags/swap-battery.svg'),
        },
        hibernate_mode: {
          icon: require('@/assets/img/flags/sleep_mode.svg'),
        },
        restricted_alert: {
          icon: require('@/assets/img/flags/restricted_area.svg'),
        },
        slowzone_alert: {
          icon: require('@/assets/img/flags/slow_area.svg'),
        },
        // operational

        // general
        geo_fence_alert: {
          icon: require('@/assets/img/flags/outside_of_service_area.svg'),
        },
        iot_fault: {
          icon: require('@/assets/img/flags/on-iot-fault.svg'),
        },
        low_battery: {
          icon: require('@/assets/img/flags/on-low-battery.svg'),
        },
        missing: {
          icon: require('@/assets/img/flags/on-missing.svg'),
        },
        // end->general

        // start->status
        is_charging: {
          icon: require('@/assets/img/flags/on-charging.svg'),
        },
        is_parking: {
          icon: require('@/assets/img/flags/on-parking.svg'),
        },
        is_reserved: {
          icon: require('@/assets/img/flags/on-reservation.svg'),
        },
        is_on_ride: {
          icon: require('@/assets/img/flags/on-ride.svg'),
        },
        is_locked: {
          icon: require('@/assets/icon/timeline/vehicle-locked.svg'),
        },
        is_unlocked: {
          icon: require('@/assets/icon/timeline/vehicle-unlocked.svg'),
        },
        inspection_required: {
          icon: require('@/assets/img/flags/inspection_required.svg'),
        },
        re_inspection: {
          icon: require('@/assets/img/flags/re_inspection.svg'),
        },
        under_inspection: {
          icon: require('@/assets/img/flags/re_inspection.svg'),
        },
        repair_required: {
          icon: require('@/assets/img/flags/re_inspection.svg'),
        },
        repair_backlog: {
          icon: require('@/assets/img/flags/re_inspection.svg'),
        },
        battery_swap_required: {
          icon: require('@/assets/img/flags/battery_swap_in_progress.svg'),
        },
        battery_swap_in_progress: {
          icon: require('@/assets/img/flags/battery_swap_in_progress.svg'),
        },

        // end->status
        none: {
          // TODO: Update icon (this is actually on-maintainance icon)
          icon: require('@/assets/img/flags/none.png'),
        },
      }
    },
    alias() {
      return {
        'charging-pick': this.collection.charging_pick,
        'maintainance': this.collection.maintainance,
        'rebalance': this.collection.rebalance,
        'swap-battery': this.collection.swap_battery,

        'geofence-fault': this.collection.geo_fence_alert,
        'iot-fault': this.collection.iot_fault,
        'low-battery': this.collection.low_battery,
        'missing': this.collection.missing,

        'on-charging': this.collection.is_charging,
        'on-parking': this.collection.is_parking,
        'on-reservation': this.collection.is_reserved,
        'on-ride': this.collection.is_on_ride,

        'is_locked': this.collection.is_locked,
        'is_unlocked': this.collection.is_unlocked,

        'none': this.collection.none,
      }
    },
    getIcon() {
      let icon = Object.keys(this.collection).includes(this.name)
        ? this.collection[this.name].icon
        : null
      // console.log("icn", icon);
      if (icon) {
        return icon
      }

      let alias = Object.keys(this.alias).includes(this.name)
        ? this.alias[this.name].icon
        : null
      // console.log("als=", alias);
      return alias || null
    },
    getIconClass() {
      let sizes = ['xs', 'sm', 'base', 'lg']
      let size = sizes.includes(this.size) ? this.size : 'base'
      return 'icon-' + size
    },
  },
}
</script>

<style lang="scss" scoped>
.icon-wrapper {
  object-fit: cover;
  overflow: hidden;

  & .icon-xs {
    width: 16px;
    height: 16px;
  }
  & .icon-sm {
    width: 19px;
    height: 19px;
  }
  & .icon-base {
    width: 32px;
    height: 32px;
  }
  & .icon-lg {
    width: 32px;
    height: 32px;
  }
}
</style>
